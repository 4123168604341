<template>
  <el-card v-loading="loading">
    <el-form :model="form" label-position="top">
      <b>ID запроса:</b>
      <p class="m-t-5">
        {{ getValue(form.id) }}
      </p>
      <b>От кого:</b>
      <p class="m-t-5">
        {{ getValue(form.from0.fio) }}
      </p>
      <b>От ИОГВ:</b>
      <p class="m-t-5">
        {{ getValue(form.from0.ebosp.short_name) }}
      </p>
      <b>За ИОГВ:</b>
      <p class="m-t-5">
        {{ getValue(form.storage.ebosp.short_name) }}
      </p>
      <b>Дата создания запроса:</b>
      <p class="m-t-5">
        {{ getFormatDate(form.createdAt) }}
      </p>
      <b>Статус:</b>
      <p>
        <el-tag>{{form.status.name}}</el-tag>
      </p>
      <div v-if="form.status_id === 4">
        <b>Период открытия доступа:</b>
        <p class="m-t-5">
          {{ `С ${getFormatDate(form.closedPeriodAccess.date_start)} по ${getFormatDate(form.closedPeriodAccess.date_stop)}` }}
        </p>
      </div>
      <b>Сообщение:</b>
      <p class="m-t-5">
        {{ getValue(form.message) }}
      </p>
      <div v-if="form.status_id === 3">
        <b>Пояснения об отклонении запроса:</b>
        <p class="m-t-5">
          {{ getValue(form.reject_message) }}
        </p>
      </div>
      <div>
        <b>Файл:</b>
        <p v-if="form.file_id">
          <el-button
            type="text"
            title="Посмотреть файл"
            icon="el-icon-download"
            @click="downloadFile(form.file_id)">
            {{ form.file.name }}
          </el-button>
        </p>
        <p v-else>Не приложен</p>
      </div>
      <template v-if="form.status_id === 2 && userCan(['control'])">
        <el-button type="danger" @click="dialogVisibleReject = true">Отклонить запрос</el-button>
        <el-button type="success" @click="dialogVisible = true">Открыть доступ</el-button>
      </template>
      <el-button type="warning" @click="dialogVisibleView = true">Просмотреть показатель</el-button>
      <el-button type="primary" @click="$router.back()">Вернуться к списку</el-button>
    </el-form>
    <el-dialog top="5vh" :visible.sync="dialogVisibleView">
      <storage-view-modal v-if="dialogVisibleView" :storage-id="form.storage_id"/>
    </el-dialog>
    <el-dialog top="5vh" :visible.sync="dialogVisible" title="Выбор дат для открытия доступа в закрытый отчетный период">
      <el-form :model="form" ref="form" label-position="top" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="date_start" label="Дата начала">
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                format="dd.MM.yyyy HH:mm:ss"
                type="datetime"
                v-model="form.date_start"
                style="width: 100%"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="date_stop" label="Дата окончания">
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                format="dd.MM.yyyy HH:mm:ss"
                type="datetime"
                v-model="form.date_stop"
                style="width: 100%"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="success" @click="acceptRequest">Открыть доступ</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog top="5vh" :visible.sync="dialogVisibleReject" title="Пояснения об отклонении запроса">
      <el-form :model="form" ref="reject_form" label-position="top">
        <el-form-item prop="reject_message" label="Пояснения об отклонении запроса"
                      :rules="[
                          { required: true, message: 'Не заполнено обязательное поле', trigger: 'blur' }
                        ]">
          <el-input type="textarea" maxlength="500" v-model="form.reject_message"/>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="rejectRequest">Отклонить запрос</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-card>
</template>

<script>
import { acceptRequest, getRequestsView, rejectRequest } from '@/api/api'
import { downloadFile } from '@/api/file'
import StorageViewModal from '@/views/storage/StorageViewModal'
import userCan from '@/utils/checkPermission'
import { getFormatDate } from '@/utils'

export default {
  components: {
    StorageViewModal
  },
  data () {
    return {
      loading: true,
      dialogVisibleView: false,
      dialogVisibleReject: false,
      dialogVisible: false,
      form: {
        id: null,
        closedPeriodAccess: {
          date_start: null,
          date_stop: null
        },
        createdAt: null,
        from0: {
          id: null,
          ebosp_id: null,
          ebosp: {
            id: null,
            ebosp_name: null,
            short_name: null,
            code: null,
            vice_governor_id: null,
            last_editor: null,
            is_deleted: false,
            inn: null,
            show_on_pki: false,
            show_on_kp: false
          },
          fio: null,
          is_deleted: false
        },
        storage: {
          ebosp: {
            short_name: null
          }
        },
        message: null,
        reject_message: null,
        file_id: null,
        status_id: null,
        storage_id: null,
        date_start: null,
        date_stop: null,
        status: {
          name: ''
        },
        file: {
          name: ''
        }
      },
      rules: {
        date_start: [
          { required: true, message: 'Не заполнено обязательное поле!', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              const dateNow = new Date()
              if (dateNow > new Date(value)) {
                callback(new Error('Нельзя выбрать дату меньше текущей'))
              } else if (this.form.date_stop && this.form.date_stop < value) {
                callback(new Error('Дата начала должна быть меньше, чем дата окончания'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: ['blur', 'change']
          }
        ],
        date_stop: [
          { required: true, message: 'Не заполнено обязательное поле!', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (this.form.date_start && this.form.date_start > value) {
                callback(new Error('Дата окончания должна быть больше, чем дата начала'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
    getRequestsView(this.$route.params.id).then(response => {
      this.form = response.data
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    getValue (val) {
      if ((typeof val) === 'boolean') {
        return val ? 'Да' : 'Нет'
      }
      if (val) {
        return val
      }
      return '\u2014'
    },
    getFormatDate,
    userCan,
    downloadFile (id) {
      downloadFile(id).then(({ data }) => {
        const url = URL.createObjectURL(new Blob([data], { type: data.type }))
        window.open(url, '_blank')
      })
    },
    rejectRequest () {
      this.$refs.reject_form.validate(valid => {
        if (valid) {
          rejectRequest(this.form).then(() => {
            this.$message.success('Запрос успешно отклонен!')
            this.$router.back()
          })
        }
      })
    },
    acceptRequest () {
      this.$refs.form.validate(valid => {
        if (valid) {
          acceptRequest(this.form).then(() => {
            this.$message.success('Доступ успешно предоставлен!')
            this.$router.back()
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
