import store from '@/store'

export default function userCan (roles) {
  const userRoles = store.state.user.roles
  if (userRoles.includes('admin')) {
    return true
  }
  for (const role of userRoles) {
    if (roles.includes(role)) {
      return true
    }
  }
  return false
}
